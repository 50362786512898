import { alpha } from './utils';

// Black Alpha ✨
export const blackBase = '#000000';
export const blackAlpha50 = alpha(blackBase, 0.02);
export const blackAlpha100 = alpha(blackBase, 0.05);
export const blackAlpha200 = alpha(blackBase, 0.1);
export const blackAlpha300 = alpha(blackBase, 0.14);
export const blackAlpha400 = alpha(blackBase, 0.2);
export const blackAlpha500 = alpha(blackBase, 0.3);
export const blackAlpha600 = alpha(blackBase, 0.45);
export const blackAlpha700 = alpha(blackBase, 0.55);
export const blackAlpha800 = alpha(blackBase, 0.65);
export const blackAlpha900 = alpha(blackBase, 0.75);
export const blackAlpha1000 = alpha(blackBase, 0.85);

// White Alpha ✨
export const whiteBase = '#ffffff';
export const whiteAlpha50 = alpha(whiteBase, 0.02);
export const whiteAlpha100 = alpha(whiteBase, 0.05);
export const whiteAlpha200 = alpha(whiteBase, 0.1);
export const whiteAlpha300 = alpha(whiteBase, 0.14);
export const whiteAlpha400 = alpha(whiteBase, 0.2);
export const whiteAlpha500 = alpha(whiteBase, 0.3);
export const whiteAlpha600 = alpha(whiteBase, 0.45);
export const whiteAlpha700 = alpha(whiteBase, 0.55);
export const whiteAlpha800 = alpha(whiteBase, 0.65);
export const whiteAlpha900 = alpha(whiteBase, 0.75);
export const whiteAlpha1000 = alpha(whiteBase, 0.85);

// Gray ✨
export const grayBase = '#DEDCD9';
export const gray50 = '#FCFBF9';
export const gray100 = '#F7F6F4';
export const gray200 = '#F2F1F0';
export const gray300 = '#EBEAE8';
export const gray400 = '#E8E6E3';
export const gray500 = grayBase;
export const gray600 = '#C5C3C1';
export const gray700 = '#A09F9D';
export const gray800 = '#747371';
export const gray900 = '#5D5B59';
export const gray1000 = '#474543';
export const gray1050 = '#363432';
export const gray1100 = '#302E2C';
export const gray1200 = '#1C1B19';
export const gray1300 = '#171717';
export const gray1400 = '#101010';

// Blue ✨
export const blueBase = '#5769E7';
export const blue50 = '#E5E9FF';
export const blue100 = '#D5DAF7';
export const blue200 = '#A9B5FC';
export const blue300 = '#8A9BFF';
export const blue400 = '#7081F3';
export const blue500 = blueBase;
export const blue600 = '#4958BE';
export const blue700 = '#3F4BA1';
export const blue800 = '#323C7C';

// Blue Alpha ✨
export const blueAlpha50 = alpha(blueBase, 0.25);
export const blueAlpha100 = alpha(blueBase, 0.35);
export const blueAlpha500 = alpha(blueBase, 0.5);
export const blueAlpha700 = alpha(blueBase, 0.6);
export const blueAlpha900 = alpha(blueBase, 0.9);

// Green ✨
export const greenBase = '#52AD6E';
export const green50 = '#F0FAF2';
export const green100 = '#E4F4E7';
export const green200 = '#CAE8CE';
export const green300 = '#A0D5B0';
export const green400 = '#6FB986';
export const green500 = greenBase;
export const green600 = '#3B8552';
export const green700 = '#2F6641';
export const green800 = '#253E2D';

// Pink Dark ✨
export const coralBase = '#FF7471';
export const coral50 = '#FFF3F2';
export const coral100 = '#FFDEDD';
export const coral200 = '#FFC0BE';
export const coral300 = '#FFA6A4';
export const coral400 = '#FF8B89';
export const coral500 = coralBase;
export const coral600 = '#C74E4C';
export const coral700 = '#A93C39';
export const coral800 = '#802E2D';

// Purple ✨
export const purpleBase = '#8E8EEA';
export const purple50 = '#F8F8FE';
export const purple100 = '#EEEFFD';
export const purple200 = '#E1E2FA';
export const purple300 = '#BCBCF3';
export const purple400 = '#A7A7EF';
export const purple500 = purpleBase;
export const purple600 = '#6C67CF';
export const purple700 = '#5E59B3';
export const purple800 = '#40346D';

// Purple Light Alpha ✨
export const purpleLightAlpha50 = alpha(purpleBase, 0.25);
export const purpleLightAlpha100 = alpha(purpleBase, 0.35);
export const purpleLightAlpha500 = alpha(purpleBase, 0.5);
export const purpleLightAlpha700 = alpha(purpleBase, 0.6);
export const purpleLightAlpha900 = alpha(purpleBase, 0.9);

// Yellow ✨
export const yellowBase = '#FFC738';
export const yellow50 = '#FFFBF1';
export const yellow100 = '#FFF4D7';
export const yellow200 = '#FFE39B';
export const yellow300 = '#FFDB7E';
export const yellow400 = '#FFD56A';
export const yellow500 = yellowBase;
export const yellow600 = '#DBAB30';
export const yellow700 = '#B28615';
export const yellow800 = '#80641C';

// Neutral Alpha ✨
export const neutralAlpha50 = alpha(gray200, 0.25);
export const neutralAlpha100 = alpha(gray200, 0.4);
export const neutralAlpha500 = alpha(gray200, 0.5);
export const neutralAlpha700 = alpha(gray200, 0.6);
export const neutralAlpha900 = alpha(gray200, 0.95);

// Sand ✨
export const sandBase = '#A59D94';
export const sand50 = '#FCF7F2';
export const sand100 = '#F3EDE7';
export const sand200 = '#DDD6CF';
export const sand300 = '#CCC5BD';
export const sand400 = '#B7AFA7';
export const sand500 = sandBase;
export const sand600 = '#8D867E';
export const sand700 = '#77716A';
export const sand800 = '#615B55';
