import {
  blackAlpha700,
  blackAlpha900,
  blue100,
  blue50,
  blue500,
  blue600,
  blue700,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  green100,
  green600,
  green700,
  coral50,
  coral100,
  coral600,
  coral800,
  purple100,
  purple50,
  whiteBase,
  yellow100,
  blue200,
  gray1100,
  gray1300,
  coral200,
  purpleLightAlpha500,
  coral700,
  blackBase,
  neutralAlpha700,
  neutralAlpha900,
  purple200,
  purple300,
  blackAlpha300,
  gray1400,
  purple700,
  gray50,
  yellow400,
  yellow200,
  green800,
  green200,
  purple600,
  purple800,
} from './palette';
import type { Colors } from './colors.types';

/** IMPORTANT! Any changes to colors needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in kosmosTokensToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the light theme class framework colors in globals.css
 */

export const colors: Colors = {
  bg: whiteBase,
  bgStrong: gray300,
  bgOverlay: blackAlpha700,
  border: gray500,
  borderBrandWeakest: blue100,
  borderDisabled: gray500,
  borderInfoWeakest: purple100,
  borderNeutralWeakest: gray300,
  borderNegativeWeakest: coral100,
  borderNoticeWeakest: yellow100,
  borderPositiveWeakest: green100,
  borderPrimaryWeakest: gray200,
  borderSecondaryWeakest: purple200,
  borderStrong: gray600,
  borderTransparent: blackAlpha300,
  borderWeak: gray300,
  borderWeaker: gray200,
  brand: blue500,
  brandWeak: blue200,
  brandWeakest: blue50,
  disabled: gray100,
  disabledStrong: gray200,
  highContrast: blackBase,
  info: purple700,
  infoWeak: purple100,
  infoWeakest: purple50,
  interactiveFocused: purpleLightAlpha500,
  negative: coral600,
  negativeWeak: coral100,
  negativeWeakest: coral50,
  neutral: gray400,
  neutralWeak: gray200,
  neutralWeakest: gray50,
  notice: yellow400,
  noticeWeak: yellow200,
  noticeWeakest: yellow100,
  onBrand: whiteBase,
  onBrandWeak: gray1400,
  onBrandWeakest: gray1400,
  onDisabled: gray500,
  onDisabledStrong: gray600,
  onHighContrast: gray100,
  onInfo: whiteBase,
  onInfoWeak: gray1400,
  onInfoWeakest: gray1400,
  onNegative: whiteBase,
  onNegativeWeak: coral800,
  onNegativeWeakest: coral800,
  onNeutral: gray1100,
  onNeutralWeak: gray1100,
  onNeutralWeakest: gray1100,
  onNotice: gray1400,
  onNoticeWeak: gray1400,
  onNoticeWeakest: gray1400,
  onPositive: whiteBase,
  onPositiveWeak: gray1400,
  onPositiveWeakest: green800,
  onPrimary: whiteBase,
  onPrimaryWeak: gray1100,
  onPrimaryWeakest: gray1100,
  onSecondary: whiteBase,
  onSecondaryWeak: gray1400,
  onSecondaryWeakest: gray1400,
  positive: green600,
  positiveWeak: green200,
  positiveWeakest: green100,
  primary: gray1400,
  primaryWeak: gray500,
  primaryWeakest: gray200,
  secondary: purple700,
  secondaryWeak: purple200,
  secondaryWeakest: purple50,
  surface: whiteBase,
  surfaceRaised: whiteBase,
  surfaceSunken: gray200,
  surfaceOverlay: whiteBase,
  surfaceOverlayTransparent: neutralAlpha700,
  surfaceOverlayTransparentHover: neutralAlpha900,
  text: gray1300,
  textDisabled: gray500,
  textLink: gray1100,
  textNegative: coral800,
  textPrimary: gray1100,
  textWeak: blackAlpha900,
  textWeaker: blackAlpha700,

  // Component tokens
  inputBg: gray200,
  inputBgActive: whiteBase,
  buttonBgBrand: blue500,
  buttonBgBrandActive: blue700,
  buttonBgBrandHover: blue600,
  buttonBgNegative: coral600,
  buttonBgNegativeActive: coral800,
  buttonBgNegativeHover: coral700,
  buttonBgNegativeWeak: coral50,
  buttonBgNegativeWeakActive: coral200,
  buttonBgNegativeWeakHover: coral100,
  buttonBgOutlineActive: gray200,
  buttonBgOutlineHover: gray100,
  buttonBgOutlineChecked: purple200,
  buttonBgOutlineCheckedActive: purple300,
  buttonBgOutlineCheckedHover: purple200,
  buttonBgPositive: green600,
  buttonBgPositiveActive: green800,
  buttonBgPositiveHover: green700,
  buttonBgPrimary: gray1100,
  buttonBgPrimaryActive: gray1400,
  buttonBgPrimaryHover: gray1300,
  buttonBgPrimaryChecked: purple600,
  buttonBgPrimaryCheckedActive: purple800,
  buttonBgPrimaryCheckedHover: purple700,
  buttonBgPrimaryReverse: whiteBase,
  buttonBgPrimaryReverseActive: gray600,
  buttonBgPrimaryReverseHover: gray400,
  buttonBgPrimaryReverseChecked: purple600,
  buttonBgPrimaryReverseCheckedActive: purple800,
  buttonBgPrimaryReverseCheckedHover: purple700,
  buttonBgSecondaryHover: gray200,
  buttonBgSecondaryActive: gray300,
  buttonBgSecondaryChecked: purple50,
  buttonBgSecondaryCheckedHover: purple100,
  buttonBgSecondaryCheckedActive: purple200,
  buttonBgTertiary: gray200,
  buttonBgTertiaryActive: gray600,
  buttonBgTertiaryHover: gray400,
  buttonBgTertiaryChecked: purple600,
  buttonBgTertiaryCheckedActive: purple800,
  buttonBgTertiaryCheckedHover: purple700,
  surfaceHover: gray100,
  surfaceActive: gray200,
  surfaceChecked: purple50,
  surfaceCheckedHover: purple200,
};
